.login {
    align-items: center;
    @include mq("tablet") {
        display: flex;
        padding: 3.125rem; // 50px
        background-image: url("../../imgs/bg-login.png"),
            linear-gradient(to right, $white, $white 58%, $primary 58%);
        background-repeat: no-repeat;
        background-position: bottom left;
    }
	#root {
		margin: 0 auto;
	}
    @include mq("tablet", max) {
        margin: 1.25rem;
    }
}

.login-wrapper {
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.43);
    margin-bottom: 0;

    @include mq("tablet", max) {
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.35);
    }
}

.logo-login {
    margin-bottom: 3.125rem;
}

.login-box {
    padding: 6.25rem; // 100px

    @include mq("desktop", max) {
        padding: 6.25rem 3.125rem; // 100px 50px
    }

    @include mq("tablet-wide", max) {
        background: $primary;
    }

    @include mq("tablet-wide", max) {
        padding: 3.125rem; // 50px
    }

    * {
        color: $white;
    }

    a,
    .btn-link {
        font-size: 0.875rem;
        &:hover,
        &:focus {
            color: $white;
        }
    }

    button {
        &:hover {
            color: $white;
        }
    }

    label {
        font-size: 0.875rem;
    }

    input {
        font-size: 0.875rem;
    }
}

.Input__radio___2hllK {
    vertical-align: middle !important;
}

