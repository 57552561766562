// This file contains  sass mixins.

// centre a block element
@mixin push--auto {
    margin: {
        left: auto;
        right: auto;
    }
}

// pseudo selector
@mixin pseudo($display: block, $pos: absolute, $content: "") {
    content: $content;
    display: $display;
    position: $pos;
}

// css triangles
@mixin css-triangle(
    $color,
    $direction,
    $size: 6px,
    $position: absolute,
    $round: false
) {
    @include pseudo($pos: $position);
    width: 0;
    height: 0;

    @if $round {
        border-radius: 3px;
    }

    @if $direction==down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round($size / 2.5);
    } @else if $direction==up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round($size / 2.5);
    } @else if $direction==right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else if $direction==left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

// font style
@mixin font-source-sans(
    $size: false,
    $colour: false,
    $weight: false,
    $lh: false
) {
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;

    @if $size {
        font-size: $size;
    }

    @if $colour {
        color: $colour;
    }

    @if $weight {
        font-weight: $weight;
    }

    @if $lh {
        line-height: $lh;
    }
}

// placeholder
@mixin input-placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}

// media query
//.site-header {
//    padding: 2rem;
//    font-size: 1.8rem;
//    @include mq('tablet-wide') {
//        padding-top: 4rem;
//        font-size: 2.4rem;
//    }
//}
$breakpoints: (
    "phone": 400px,
    "phone-wide": 480px,
    "phablet": 560px,
    "tablet-small": 640px,
    "tablet": 768px,
    "tablet-wide": 1024px,
    "desktop": 1248px,
    "desktop-wide": 1440px,
);

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);

        @if $type==max {
            $width: $width - 1px;
        }

        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

// truncate
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// selection
@mixin selection {
    ::-moz-selection {
        @content;
    }
    ::selection {
        @content;
    }
}
