// This file contains very basic styles.

// Set up a decent box model on the root element
html {
  box-sizing: border-box;
  height: 100%;
}

body {
  height: 100%;
  background-size: contain;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Basic styles for links
a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: 1201 !important;
  .MuiCircularProgress-root{
    color: $secondary;
  }
}

// google traslate
.assistoc-gtraslate{
  text-align: right;
  padding: 5px 10px 0 0;
  .goog-te-gadget{
    color: #fff;
  }
  .goog-logo-link, .goog-logo-link:link, .goog-logo-link:visited, .goog-logo-link:hover, .goog-logo-link:active{
    color: #fff;
  }
}

.landing-gtraslate{
  display: inline-block;
  vertical-align: middle;
}

.assistoc-speeddial{
  position: fixed;
  bottom: 25px;
  right: 20px;
  .MuiFab-primary{
    background-color: $primary;
    &:hover{
      background-color: #003c6b;
    }
    &:focus{
      outline: 0;
    }
  }
}
