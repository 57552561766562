.table-fs-14 {
    thead,
    tbody {
        th,
        td {
            font-size: 0.875rem;
        }
    }
}

@include mq("tablet", max) {
    .input-label {
        font-size: 1rem;
        text-align: left;
    }
    .table {
        &.file-table {
            tr {
                border-bottom: 1px solid #ccc;
                th,
                td {
                    position: relative;
                    border: none;
                    display: block;

                    &:last-child {
                        padding: 0;
                    }

                    i.fas {
                        position: absolute;
                        bottom: 1.85rem;
                        right: 1rem;
                        right: 1rem;
                        z-index: 1;
                    }
                }
            }
        }
    }
}

.alert-msg-2 {
    position: fixed !important;
    top: 50%;
    height: 150px;
    text-align: center;
    left: 50%;
    width: 300px;
    background-color: #2d52a4 !important;
    color: white;
    z-index: 5;
    transform: translate(-50%, -50%);
}
