body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #f4f4f4;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 40px 20px;
}

.page .notice {
  color: red;
}

.page img {
  display: block;
  max-width: 100%;
}

.welcome {
  padding-top: 20px;
  text-align: center;
  font-size: 40px;
  padding-bottom: 15px;
  color: white;
  font-family: Alegreya Sans;
}
.login-block {
  text-align: center;
  background-color: #1864af;
  width: 100vw;
  margin: 0px;
}

.register-user-button {
  text-align: center;
  height: 70px;
  width: 35%;
  widows: 75px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ff9500;
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin: 20px;
  margin-right: 5px;
  border: hidden;
}

.submit-claim-button {
  text-align: center;
  height: 70px;
  width: 35%;
  widows: 75px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ff9500;
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin: 20px;
  margin-left: 5px;
  border: hidden;
}

.signout-button {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 7vh;
  width: 100px;
  widows: 75px;
  cursor: pointer;
  background-color: #ff9500;
  color: white;
  font-weight: bold;
  font-size: 15px;
  border: hidden;
  border-radius: 5px;
}

.reset-password {
  font-size: small;
  color: white;
}

.note {
  color: white;
  margin-bottom: 10px;
}

.input-field {
  position: relative;
  width: 20%;
  height: 30px;
  margin: 5px;
  border-radius: 5px;
  font-size: 15px;
  border: hidden;
}

.input-field-2 {
  position: relative;
  width: 20%;
  height: 30px;
  margin: 5px;
  border-radius: 5px;
  font-size: 15px;
  border: hidden;
}

.input-label {
  display: inline;
  text-align: center;
  color: black;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}

.input-line {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  display: inline;
}

.workstatus {
  margin-top: 5px;
  padding-top: 0px;
}

.login-button {
  height: 35px;
  width: 20%;
  widows: 75px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ff9500;
  color: white;
  font-weight: bold;
  font-size: 15px;
  border: hidden;
  margin-top: 10px;
}

.bottom-login {
  padding-bottom: 20px;
}

.banner {
  display: flex;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  padding: 0px;
  background-color: #f4f4f4;
  height: 7vh;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.banner-img {
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  border-radius: 10px;
}

.banner-text {
  color: #2d52a4;
  font-size: 50px;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.register-button {
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
}

.reset-password {
  cursor: pointer;
  font-weight: bold;
  display: inline-flex;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
  font-size: small;
}

.section-1 {
  height: 33vh;
}

.content {
  text-align: center;
  background-color: #f4f4f4;
  margin-left: auto;
  margin-right: auto;
}

.SessionInfo {
  text-align: center;
  background-color: white;
  width: 80%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
}

.heart-img {
  position: relative;
  margin-bottom: 10px;
  width: 840px;
  height: 400px;
}

.add-user-form {
  text-align: center;
  background-color: #1864af;
  width: 100vw;
  margin: 0px;
  height: 100vh;
  overflow-y: scroll;
}

.panel-title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: white;
  padding: 30px;
  padding-bottom: 10px;
  font-size: x-large;
}

.submit-claim-form {
  text-align: center;
  background-color: rgb(39, 39, 39);
  width: 655px;
  height: 50vh;
  padding-top: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  border-radius: 10px;
}

.close-panel {
  position: relative;
  height: 30px;
  font-weight: bold;
  font-size: large;
  color: white;
  background-color: #ff9500;
  border: hidden;
  cursor: pointer;
  float: left;
}

.add-user-button {
  height: 30px;
  width: 150px;
  font-weight: bold;
  font-size: large;
  border-radius: 5px;
  color: white;
  background-color: #ff9500;
  border: hidden;
  cursor: pointer;
  margin-top: 15px;
}

.submit-claim-button2 {
  height: 30px;
  width: 100px;
  font-weight: bold;
  font-size: large;
  border-radius: 5px;
  color: white;
  background-color: #ff9500;
  border: hidden;
  cursor: pointer;
}

.message {
  text-align: center;
  font-family: Teko;
  font-size: 45px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  color: #151984;
}

.message2 {
  font-family: Teko;
  text-align: center;
  font-style: italic;
  font-size: 30px;
  padding-top: 5px;
  padding-bottom: 20px;
  font-weight: bold;
  color: #151984;
}

.alert-msg {
  margin: 10px;
  color: rgb(253, 253, 253);
  font-size: 20px;
  padding-bottom: 10px;
}

.alert-msg-2 {
  position: absolute;
  top: 30vh;
  height: 150px;
  text-align: center;
  left: 35vw;
  width: 300px;
  background-color: #2d52a4;
  color: white;
  z-index: 5;
}

.close-panel2 {
  position: relative;
  width: 50px;
  height: 30px;
  font-weight: bold;
  font-size: large;
  color: white;
  background-color: #ff9500;
  border: hidden;
  cursor: pointer;
  float: right;
}

.alert-text {
  color: rgb(255, 255, 255);
  padding-top: 50px;
  font-size: large;
}

.file-input {
  color: black;

  margin-bottom: 10px;
}

.user-info {
  text-align: center;
  background-color: #f4f4f4;
  width: 70vw;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  border: 1px solid black;
}

table {
  border-collapse: collapse;
}

.file-table {
  padding: 0px;
  width: 100%;
}

.file-table td {
  border: none;
  padding: 1px;
}

.file-table .row {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.file-table .col1 {
  border-right: 1px solid black;
  padding-right: 5px;
}

td {
  /* border: 1px solid black; */
  padding: 5px;
}

.col1 {
  text-align: right;
  font-weight: bold;
}

.check {
  width: 30px;
  height: 30px;
}

.status-box {
  display: inline-flex;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
}

.status-label {
  font-size: x-large;
  width: 150px;
  display: inline-flex;
  margin-top: auto;
  margin-bottom: auto;
}

.status-msg {
  font-size: 22px;
  display: inline-flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.refresh-button {
  height: 30px;
  width: 100px;
  font-weight: bold;
  font-size: large;
  border-radius: 5px;
  color: white;
  background-color: #ff9500;
  border: hidden;
  cursor: pointer;
  height: 40px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.note2 {
  color: black;
  display: inline-flex;
  padding-right: 5px;
  font-weight: bold;
}

.file-input-section {
  background-color: white;
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0px;
  box-sizing: border-box;
}

.survey {
  margin-left: 30px;
}

.correct {
  margin-right: 150px;
}

@media only screen and (max-width: 600px) {
  .banner-img {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    border-radius: 10px;
  }

  .file-input-section {
    background-color: white;
    width: 85vw;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
  .user-info {
    text-align: center;
    background-color: #f4f4f4;
    width: 85vw;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
    border: 1px solid black;
  }
  .note2 {
    color: black;
    display: inline-flex;
    padding-right: 5px;
  }
  .input-label {
    display: inline-block;
    font-size: small;
    padding: 0px;
  }
  .refresh-button {
    height: 30px;
    width: 100px;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
    height: 40px;
    margin-right: 5px;
  }

  .login-block {
    text-align: center;
    background-color: #1864af;
    width: 100vw;
    margin: 0px;
  }

  .check {
    width: 20px;
    height: 20px;
  }

  .input-line {
    text-align: center;
    display: inline;
    background-color: white;
    padding: 0px;
    padding-left: 0px;
    padding-bottom: 5px;
  }

  .signout-button {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 7vh;
    width: 65px;
    widows: 75px;
    cursor: pointer;
    background-color: #ff9500;
    color: white;
    font-weight: bold;
    font-size: 15px;
    border: hidden;
    border-radius: 5px;
  }

  .heart-img {
    width: 350px;
    height: 200px;
  }

  .add-user-form {
    text-align: center;
    background-color: #1864af;
    width: 100vw;
    margin: 0px;
    height: 80vh;
    padding-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  .add-user-button {
    height: 30px;
    width: 150px;
    font-weight: bold;
    font-size: large;
    border-radius: 5px;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
    margin-top: 10px;
  }

  .input-field {
    position: relative;
    width: 50%;
    height: 30px;
    margin: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
  }

  .login-button {
    height: 35px;
    width: 50%;
    widows: 75px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #ff9500;
    color: white;
    font-weight: bold;
    font-size: 15px;
    border: hidden;
  }

  .input-field-2 {
    position: relative;
    width: 60%;
    height: 30px;
    margin: 5px;
    border-radius: 5px;
    font-size: 15px;
    border: hidden;
  }

  .submit-claim-form {
    text-align: center;
    background-color: rgb(48, 47, 47);
    margin-right: auto;
    margin-left: auto;
    margin-top: 0px;
    width: 95vw;
    height: 50vh;
    border-radius: 10px;
  }

  .banner-text {
    text-align: center;
    color: #2d52a4;
    font-size: 35px;
    width: 200px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
  }

  .close-panel {
    position: relative;
    height: 30px;
    font-weight: bold;
    font-size: large;
    color: white;
    background-color: #ff9500;
    border: hidden;
    cursor: pointer;
    float: left;
  }
  .message {
    text-align: center;
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: bold;
    color: #151984;
  }

  .message2 {
    text-align: center;
    font-style: italic;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
    font-weight: bold;
    color: #151984;
  }

  .register-user-button {
    text-align: center;
    height: 60px;
    width: 35%;
    widows: 75px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #ff9500;
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin: 10px;
    margin-right: 5px;
    border: hidden;
  }
}

/* Login content Css START */
.login-content {
  background-color: #fff;
  width: 70vw;
  margin: 20px auto;
  padding: 15px;
  border: 1px solid black;
  border-radius: 6px;
  box-sizing: border-box;
}
.login-content a {
  color: #ff9500;
}
.login-info {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.login-info:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}
.login-info-title {
  font-size: 22px;
  font-weight: bold;
  color: #151984;
  margin-bottom: 10px;
}
.login-info-text {
  margin-bottom: 10px;
  margin-top: 0px;
}
.login-info ul {
  margin-top: 0;
  margin-bottom: 10px;
}

/* Login content Css END */

/* Frequently Asked Questions Css START */
.text-center {
  text-align: center;
}
.font-weight-bold {
  font-weight: bold;
}
.mt-0 {
  margin-top: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.border-bottom-0 {
  border-bottom: 0px !important;
}
.d-block {
  display: block;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.faq-container {
  background-color: #1864af;
  margin: 0px;
  padding-bottom: 30px;
}
.faq-container a {
  color: #ff9500;
}
.faq-content {
  background-color: #fff;
  width: 70vw;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 6px;
  box-sizing: border-box;
}

.faq-header {
  text-align: center;
  color: #d32f2f;
  font-weight: bold;
  padding: 15px;
  background-color: #f4f4f4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.faq-body {
  padding: 15px;
}

.faq-question {
  padding-bottom: 10px;
  padding-left: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  position: relative;
}
.faq-question:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}
.faq-question-title {
  font-size: 1.375rem; /*22px*/
  font-weight: bold;
  color: #151984;
  margin-bottom: 10px;
}
.faq-title {
  font-size: 1.375rem; /*22px*/
  font-weight: bold;
  color: #151984;
  margin-bottom: 10px;
}
.faq-question-title::before {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: 5px;
  background-image: url("imgs/question-icon.png");
}
.version-info {
  margin-top: 0px;
  margin-bottom: 5px;
  font-style: italic;
}
/* Frequently Asked Questions Css END */

/* Footer css START */
.footer {
  padding: 10px;
  text-align: center;
  background-color: #151984;
}
.footer a {
  padding: 10px;
  color: #ff9500;
  font-weight: bold;
  display: inline-block;
}
/* Footer css END */

@media only screen and (max-width: 600px) {
  .faq-content {
    width: 85vw;
  }
}

input[type=file] { width: 250px; overflow: hidden; }
