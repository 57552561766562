  .coming-soon{
      height: 100%;
      #root{
          height: 100%;
      }
      main{
          padding: 0;
      }
      .bgimg {
        background-image: url("../../imgs/irvine-bg-1.jpg");
        height: 100%;
        background-position: center;
        background-size: cover;
        position: relative;
        color: white;
        font-family: "Courier New", Courier, monospace;
        font-size: 25px;
      }
      
      .topleft {
        position: absolute;
        top:15px;
        left: 16px;
      }
      
      .bottomleft {
        position: absolute;
        bottom: 0;
        left: 16px;
      }
      
      .middle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        h1{
            font-size: 3.125rem;
            font-weight: 700;

            @media only screen and (max-width: 767px) {
              font-size: 2rem;
            }
        }
        p{
            font-size: 1.875rem;
            @media only screen and (max-width: 767px) {
              font-size: 13px;
            }
        }
      } 
      
      hr {
        margin: auto;
        width: 40%;
        border-top: 1px solid #fff;
      }
  }
  
  