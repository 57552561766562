// This file contains basic typography style.

html,
body {
  //color: $black;
  font-size: 16px; // 1rem = 16px;
  font-family: $roboto;
  @include mq("tablet-wide", max) {
    font-size: 15px;
  }
  @include mq("tablet", max) {
    font-size: 14px;
  }
  @include mq("tablet-small", max) {
    font-size: 13px;
  }
  @include mq("phone-wide", max) {
    font-size: 12px;
  }
}

.title {
  font-family: $league;
}
