.navbar-inner {
    padding: 1.625rem 3.625rem; //26px 58px

    @include mq("desktop", max) {
        padding: 1.25rem 1.875rem; //20px 30px
    }

    @include mq("phablet", max) {
        .logo {
            height: 25px;
        }
    }
}

.main {
    padding: 0 2.6875rem; // 0 43px(58px-15px)

    @include mq("desktop", max) {
        padding: 0.9375rem; //15px
    }
}

.welcome-box {
    padding: 5.625rem 8.125rem; //90px 130px

    @include mq("desktop-wide", max) {
        padding: 2.5rem 5rem; //40px 80px
    }

    @include mq("desktop", max) {
        padding: 2rem; //32px
    }

    @include mq("desktop", max) {
        padding: 1rem; //32px
    }
    p,
    a {
        font-size: 1.125rem; //18px
    }

    .text-welcome {
        font-weight: 300;
        font-size: 1.5rem; //24px
        color: #545454;
    }

    .title {
        font-size: 2.25rem; //36px
    }

    .text-claim {
        font-weight: 500;
        font-size: 1.25rem; //20px
        display: inline-block;
    }

    hr {
        margin: 3rem 0;
        @include mq("desktop-wide", max) {
            margin: 2rem 0;
        }
    }
}

.hero-image {
    height: 100%;
    background-size: cover;
    background-position: center center;
}
